const routes = [
  {
    name: 'product-global',
    path: '/global/qrcode/:id',
    component: () => import('@/pages/product-global/index.vue')
  },
  {
    name: 'product-global-user-rule',
    path: '/rules/global',
    component: () => import('@/pages/rule/GlobalUserRule.vue')
  }
]

export default routes
