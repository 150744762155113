import home from '@/router/routes/home'
import user from '@/router/routes/user'
import tutor from '@/router/routes/tutor'
import course from '@/router/routes/course'
import order from '@/router/routes/order'
import staticPage from '@/router/routes/static-page'
import rechargePackage from '@/router/routes/package'
import questionnaire from '@/router/routes/questionnaire'
import rule from '@/router/routes/rule'
import guide from '@/router/routes/guide'
import auth from '@/router/routes/auth'
import group from '@/router/routes/group'
import oneToOne from '@/router/routes/1v1'
import Activity from '@/router/routes/activity'
import StudyCenter from '@/router/routes/study-center'
import Zoom from '@/router/routes/zoom'
import HiLink from '@/router/routes/hilink'
import Aic from '@/router/routes/aic'
// import Chat from '@/router/routes/chat'
import Offer from '@/router/routes/offer'
import Unik from '@/router/routes/unik'
import ProductGlobal from '@/router/routes/product-global'

export default [
  ...home,
  ...user,
  ...tutor,
  ...course,
  ...order,
  ...staticPage,
  ...rechargePackage,
  ...questionnaire,
  ...rule,
  ...guide,
  ...auth,
  ...group,
  ...oneToOne,
  ...Activity,
  ...StudyCenter,
  ...Zoom,
  ...HiLink,
  ...Aic,
  // ...Chat,
  ...Offer,
  ...Unik,
  ...ProductGlobal,
  {
    name: 'teacherDay',
    path: '/teacher-day',
    meta: {
      title: '教师节',
    },
    component: () => import('@/pages/TeacherDay.vue')
  },
  {
    name: 'DEV_WEHCAT_OAUTH',
    path: '/__dev__wehcat_oauth',
    component: () => import('@/pages/__dev__/wechat-oauth.vue')
  },
  {
    name: 'worry-free',
    path: '/h5/worry-free',
    component: () => import('@/pages/h5/WorryFree.vue')
  }
]
