import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import http from '@/utils/http'
import AlloyFinger from 'alloyfinger'
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue'
import katexs from '@/utils/katex/katexs'
import store from './store'
import { etl } from '@/utils/ETL'
import { requireUrl } from '@/utils/helpers'

import LabelTimeLocal from '@components/LabelTimeLocal.vue'

import { PluginSentry } from '@/plugin/sentry'
import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html'
import '@kangc/v-md-editor/lib/style/preview-html.css'
// 引入使用主题的样式
import '@kangc/v-md-editor/lib/theme/style/github.css'
import {
  SubmitBar,
  Cell,
  CellGroup,
  Field,
  Button,
  ActionSheet,
  Popup,
  Icon,
  Row,
  Col,
  Tab,
  Tabs,
  Search,
  NoticeBar,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  NavBar,
  Image,
  DatePicker,
  Tag,
  CouponCell,
  CouponList,
  Picker,
  Collapse,
  CollapseItem,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Skeleton,
  List,
  CountDown,
  Form,
  Divider,
  Grid,
  GridItem,
  Progress,
  Overlay,
  Loading,
  Popover,
  Sticky,
  Lazyload,
  TimePicker,
  PickerGroup,
  // 兼容 this.$toast
  showSuccessToast,
  showFailToast,
  showLoadingToast,
  showToast,
  closeToast,

  // 兼容 this.$notify
  Notify,
  showNotify,
  closeNotify,

  // 兼容 this.$dialog
  Dialog,
  showDialog,
  showConfirmDialog,
  closeDialog
} from 'vant'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import '@/assets/css/katex/index.css'
import 'vant/lib/index.css'
import '@sass/app.scss'

import VConsole from 'vconsole'
import { createHead, VueHeadMixin } from '@unhead/vue'

const isMobile = /(android|iphone|symbianos|windows phone|ipod)/i.test(navigator.userAgent.toLowerCase())

if (!isMobile) {
  import('@vant/touch-emulator')
}

if (import.meta.env.VITE_APP_ENV !== 'production') {
  const vConsole = new VConsole()
}

const app = createApp(App)

app.use(AlloyFingerPlugin, {
  AlloyFinger
})

app.use(VuePlyr, {
  plyr: {}
})

app.component('LabelTimeLocal', LabelTimeLocal)
app.use(VMdPreviewHtml)
app.use(SubmitBar)
app.use(Cell)
app.use(CellGroup)
app.use(Field)
app.use(Button)
app.use(ActionSheet)
app.use(Popup)
app.use(Icon)
app.use(Row)
app.use(Col)
app.use(Tab)
app.use(Tabs)
app.use(Search)
app.use(NoticeBar)
app.use(Swipe)
app.use(SwipeItem)
app.use(Tabbar)
app.use(TabbarItem)
app.use(NavBar)
app.use(Image)
app.use(DatePicker)
app.use(Tag)
app.use(CouponCell)
app.use(CouponList)
app.use(Picker)
app.use(Collapse)
app.use(CollapseItem)
app.use(CheckboxGroup)
app.use(Checkbox)
app.use(RadioGroup)
app.use(Radio)
app.use(Skeleton)
app.use(List)
app.use(CountDown)
app.use(Form)
app.use(Divider)
app.use(Grid)
app.use(GridItem)
app.use(Progress)
app.use(Overlay)
app.use(Loading)
app.use(Popover)
app.use(Sticky)
app.use(Lazyload)
app.use(Dialog)
app.use(Notify)
app.use(TimePicker)
app.use(PickerGroup)

app.use(router)
app.use(store)

app.use(PluginSentry)

const head = createHead()
app.use(head)
app.mixin(VueHeadMixin) // Vue 3 Options API

app.config.globalProperties.$http = http
app.config.globalProperties.requireUrl = requireUrl

/**
 * 兼容 this.$toast
 */
const ToastObj: any = showToast
ToastObj.loading = showLoadingToast
ToastObj.success = showSuccessToast
ToastObj.fail = showFailToast
ToastObj.clear = closeToast

app.config.globalProperties.$toast = ToastObj

/**
 * 兼容 this.$notify
 */
const NotifyObj: any = showNotify
NotifyObj.clear = closeNotify

app.config.globalProperties.$notify = NotifyObj

/**
 * 兼容 this.$dialog
 */
const DialogObj: any = showDialog
DialogObj.alert = showDialog
DialogObj.confirm = showConfirmDialog
DialogObj.close = closeDialog

app.config.globalProperties.$dialog = DialogObj

etl.init()
app.directive('katex', katexs)
app.mount('#app')
