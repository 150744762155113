const routes = [
  {
    name: 'unik.course',
    path: '/unik/courses/:id',
    component: () => import('@/pages/unik/course.vue'),
    meta: {
      auth: false
    }
  }
]

export default routes
